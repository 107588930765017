import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import AuthenticatedApp from "./components/AuthenticatedApp";
import { BriefsList } from "./components/Briefs/BriefsList";
import { Faq } from "./components/Faq/Faq";
import { CreateItem } from "./components/Items/Create/CreateItem";
import { ItemsList } from "./components/Items/List/ItemsList";
import { NotFoundPage } from "./components/NotFoundPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { CreateReportSections } from "./components/Reports/Create/Sections/CreateReportSections";
import { CreateSection } from "./components/Reports/Create/Sections/CreateSection";
import { Reports } from "./components/Reports/List/Reports";
import { SendoutsList } from "./components/Sendouts/SendoutsList";
import { Preferences } from "./components/Settings/Preferences";

export const getRouter = ({ couldCreateReport, canSeeBriefs, scrollRef }) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AuthenticatedApp scrollRef={scrollRef} />} errorElement={<NotFoundPage />}>
        <Route path="/" element={<Reports />} />
        <Route path="/reports" element={<Reports />} />
        <Route element={<ProtectedRoute hasAccess={canSeeBriefs} />}>
          <Route path="/briefs" element={<BriefsList />} />
        </Route>
        <Route path="/faq" element={<Faq />} />
        <Route path="/settings" element={<Preferences />} />
        <Route element={<ProtectedRoute hasAccess={couldCreateReport} />}>
          <Route path="/items" element={<ItemsList />} />
          <Route path="/createItem" element={<CreateItem scrollRef={scrollRef} />} />
          <Route path="/editItem/:itemId" element={<CreateItem scrollRef={scrollRef} />} />
          <Route path="/createReport" element={<CreateReportSections />} />
          <Route path="/createSection" element={<CreateSection />} />
          <Route path="/sendouts" element={<SendoutsList />} />
          <Route path="/editSendout/:sendoutId" element={<SendoutsList />} />
        </Route>
      </Route>
    )
  );
