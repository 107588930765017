import { Breakpoints, ButtonIcon, Stack, Text, Tooltip, useHasMaxWidth } from "@secuis/ccp-react-components";
import { isEqual } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";
import { selectBriefsFilters } from "../../store/briefs/BriefsSelectors";
import { getLocalDate } from "../../utilities/dateUtils";

export const ListDateRange = () => {
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { i18n, t } = useTranslation();
  const { start_date, end_date, ...filters } = useAppSelector(selectBriefsFilters);
  const restFilters = [
    ...Object.entries(filters)
      .filter(([_, value]) => value !== null && value !== "")
      .map(([key]) => key),
  ];

  let dateText = t("Briefs.listDateRange.onlyToday");
  if (restFilters.length > 0) {
    dateText = t("Briefs.listDateRange.all");
  }
  if (start_date && !end_date) {
    dateText = t("Briefs.listDateRange.from", { from: getLocalDate(new Date(start_date), i18n.language, false, false) });
  }
  if (start_date && end_date) {
    if (!isEqual(new Date(start_date), new Date(end_date))) {
      dateText = t("Briefs.listDateRange.fromTo", {
        from: getLocalDate(new Date(start_date), i18n.language, false, false),
        to: getLocalDate(new Date(end_date), i18n.language, false, false),
      });
    } else {
      dateText = t("Briefs.listDateRange.from", { from: getLocalDate(new Date(start_date), i18n.language, false, false) });
    }
  }

  return (
    <Stack alignItems="center" gap="XS">
      <Text color="neutral" small truncate>
        {dateText}
      </Text>
      {!isMobile && (
        <Tooltip message={t("Briefs.listDateRange.tooltip")} place="top-start">
          <ButtonIcon icon="Info" mode="stateless" iconSize="M" />
        </Tooltip>
      )}
    </Stack>
  );
};
