export const overridedStyles = `
color: #F7F7F7;
p {
    font-family: Arial, sans-serif !important;
    line-height: 16pt !important;
    color: #F7F7F7;

}

span {
    font-family: Arial, sans-serif !important;
    line-height: 16pt !important;
}

div {
    background-color: rgb(14, 40, 61) !important;    
    font-family: Arial, sans-serif !important; 
    line-height: 16pt !important;
} 
html {
    background-color: rgb(14, 40, 61) !important;   
    font-family: Arial, sans-serif  !important;
    line-height: 16pt !important;
    p {
        font-family: Arial, sans-serif !important;
        line-height: 16pt !important;
        color: #F7F7F7;
    }
    span {
        font-family: Arial, sans-serif !important;
        line-height: 16pt !important;
    }
    
}
 body {
    background-color: rgb(14, 40, 61) !important;  
    font-family: Arial, sans-serif !important;
    line-height: 16pt !important;

    p {
        font-family: Arial, sans-serif !important;
        line-height: 16pt !important;
        color: #F7F7F7;
    }
    span {
        font-family: Arial, sans-serif !important;
        line-height: 16pt !important;
    }
    
}

html:has(.horizontal-scroll)::-webkit-scrollbar {
    display: none !important;
}

html::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    background-color: #172F48;
}

html::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #587CAA;
}

 `;

const OverideComponent = (elem) => {
  const result = elem; //?.replace(/&nbsp;/g, " ")?.replace(/&amp;/g, "&");
  return `<div style="font-family: Arial, sans-serif !important">${result}</div> `;
};

const DAILY_GLOBAL_TEMPLATE = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">Daily Global Intelligence Report &ndash; DD Month</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">Summary</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">The Risk Intelligence Center (RIC) Daily Global Intelligence Report (INTREP) provides an overview of significant developments in the global security and threat landscape (Monday &ndash; Friday), to raise awareness of potential threats and flashpoints of risk, to support security decision-making. Items of intelligence interest featured in this INTREP have been selected based on their severity and ability to influence the security and threat landscape.</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">APAC: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE: Sample text</li>
</ul>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">APAC</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong><span style="color: #fc263f;">&nbsp;</span></strong></p>`;

const DAILY_GLOBAL_TEMPLATE_MAIN = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">Daily Global Intelligence Report &ndash; DD Month</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">Summary</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">The Risk Intelligence Center (RIC) Daily Global Intelligence Report (INTREP) provides an overview of significant developments in the global security and threat landscape (Monday &ndash; Friday), to raise awareness of potential threats and flashpoints of risk, to support security decision-making. Items of intelligence interest featured in this INTREP have been selected based on their severity and ability to influence the security and threat landscape.</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">APAC: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE: Sample text</li>
</ul>`;
const DAILY_GLOBAL_TEMPLATE_AFRICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const DAILY_GLOBAL_TEMPLATE_APAC = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">APAC</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const DAILY_GLOBAL_TEMPLATE_LATIN_AMERICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const DAILY_GLOBAL_TEMPLATE_NORTH_AMERICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const DAILY_GLOBAL_TEMPLATE_NORTH_EUROPE = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const DAILY_GLOBAL_TEMPLATE_SOUTH_EUROPE = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;

const WEEKLY_GLOBAL_TEMPLATE = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">Weekly Global Intelligence Outlook &ndash; DD Month</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">Summary</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">The Risk Intelligence Center (RIC) Weekly Intelligence Outlook provides an overview of significant developments in the global security and threat landscape in the next week, to raise awareness of potential threats and flashpoints of risk, to support security decision-making. Items of intelligence interest featured in this INTREP have been selected based on their severity and ability to influence the security and threat landscape.</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">APAC: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE: Sample text</li>
</ul>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">APAC</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">&nbsp;</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;

const WEEKLY_GLOBAL_TEMPLATE_MAIN = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">Weekly Global Intelligence Outlook &ndash; DD Month</p>
<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">Summary</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">The Risk Intelligence Center (RIC) Weekly Intelligence Outlook provides an overview of significant developments in the global security and threat landscape in the next week, to raise awareness of potential threats and flashpoints of risk, to support security decision-making. Items of intelligence interest featured in this INTREP have been selected based on their severity and ability to influence the security and threat landscape.</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">APAC: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE: Sample text</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE: Sample text</li>
</ul>`;
const WEEKLY_GLOBAL_TEMPLATE_AFRICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">AFRICA &amp; MIDDLE EAST</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const WEEKLY_GLOBAL_TEMPLATE_APAC = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">APAC</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const WEEKLY_GLOBAL_TEMPLATE_LATIN_AMERICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">LATIN AMERICA &amp; THE CARIBBEAN</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const WEEKLY_GLOBAL_TEMPLATE_NORTH_AMERICA = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH AMERICA</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const WEEKLY_GLOBAL_TEMPLATE_NORTH_EUROPE = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">NORTH &amp; WEST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;
const WEEKLY_GLOBAL_TEMPLATE_SOUTH_EUROPE = `<p style="margin: 0cm 0cm 12pt; line-height: 16pt; font-size: 14pt; font-family: Arial, sans-serif; font-weight: bold;">SOUTH &amp; EAST EUROPE</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Sample text content here]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">[Insert image here &ndash; 568px width]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong style="color: rgb(252, 38, 63);">Comment:</strong> [Comment text here] <strong style="color: rgb(252, 38, 63);">End.</strong></p>`;

const THREAT_FORECAST_TEMPLATE = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">Threat Forecast for Month 202X</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Please see attached the RIC Threat Forecast for [Month] 202X.</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">If you have any questions about the report, please contact the Risk Intelligence Center.</p>`;

const SITREP_TEMPLATE = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">SITREP &ndash; [SITREP TITLE]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Please see attached RIC SITREP regarding [subject of SITREP].</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 1</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 2</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 3</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 4 [if applicable]</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 5 [if applicable]</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong>The Risk Intelligence Center (RIC) assesses&hellip;</strong></li>
</ul>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">If you have any questions about the report, please contact the Risk Intelligence Center.</p>`;

const INTSUM_TEMPLATE = `<h1 style="margin: 24pt 0cm 12pt; break-after: avoid; font-size: 20pt; font-family: Arial, sans-serif;">Risk Intelligence Center</h1>
<h2 style="margin: 8pt 0cm 6pt; line-height: 16pt; break-after: avoid; font-size: 13pt; font-family: Arial, sans-serif; color: gray;">RIC INTSUM for Month 202X</h2>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Please see attached the RIC monthly intelligence summary (INTSUM) for [Month] 202X.</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">If you have any questions about the report, please contact the Risk Intelligence Center.</p>`;

const INTREP_TEMPLATE = `<p style="margin: 12pt 0cm; font-size: 20pt; font-family: Arial, sans-serif; font-weight: bold;">Risk Intelligence Center</p>
<p style="margin: 12pt 0cm; line-height: 16pt; font-size: 13pt; font-family: Arial, sans-serif; color: rgb(170, 192, 219); font-weight: bold;">INTREP &ndash; [INTREP TITLE]</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">&nbsp;</p>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Please see attached RIC INTREP regarding [subject of INTREP].</p>
<ul style="margin-bottom: 0cm; margin-top: 16px;">
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 1</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 2</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 3</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 4 [if applicable]</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">Priority Intelligence 5 [if applicable]</li>
<li style="margin: 12pt 0cm 12pt 0px; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;"><strong>The Risk Intelligence Center (RIC) assesses&hellip;</strong></li>
</ul>
<p style="margin: 0cm; line-height: 16pt; font-size: 12pt; font-family: Arial, sans-serif;">If you have any questions about the report, please contact the Risk Intelligence Center.</p>`;

export const DAILY_GLOBAL = OverideComponent(DAILY_GLOBAL_TEMPLATE);

export const DAILY_GLOBAL_MAIN = OverideComponent(DAILY_GLOBAL_TEMPLATE_MAIN);
export const DAILY_GLOBAL_AFRICA = OverideComponent(DAILY_GLOBAL_TEMPLATE_AFRICA);
export const DAILY_GLOBAL_APAC = OverideComponent(DAILY_GLOBAL_TEMPLATE_APAC);
export const DAILY_GLOBAL_LATIN_AMERICA = OverideComponent(DAILY_GLOBAL_TEMPLATE_LATIN_AMERICA);
export const DAILY_GLOBAL_NORTH_AMERICA = OverideComponent(DAILY_GLOBAL_TEMPLATE_NORTH_AMERICA);
export const DAILY_GLOBAL_NORTH_EUROPE = OverideComponent(DAILY_GLOBAL_TEMPLATE_NORTH_EUROPE);
export const DAILY_GLOBAL_SOUTH_EUROPE = OverideComponent(DAILY_GLOBAL_TEMPLATE_SOUTH_EUROPE);

export const WEEKLY_GLOBAL = OverideComponent(WEEKLY_GLOBAL_TEMPLATE);

export const WEEKLY_GLOBAL_MAIN = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_MAIN);
export const WEEKLY_GLOBAL_AFRICA = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_AFRICA);
export const WEEKLY_GLOBAL_APAC = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_APAC);
export const WEEKLY_GLOBAL_LATIN_AMERICA = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_LATIN_AMERICA);
export const WEEKLY_GLOBAL_NORTH_AMERICA = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_NORTH_AMERICA);
export const WEEKLY_GLOBAL_NORTH_EUROPE = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_NORTH_EUROPE);
export const WEEKLY_GLOBAL_SOUTH_EUROPE = OverideComponent(WEEKLY_GLOBAL_TEMPLATE_SOUTH_EUROPE);

export const THREAT_FORECAST = OverideComponent(THREAT_FORECAST_TEMPLATE);

export const SITREP = OverideComponent(SITREP_TEMPLATE);

export const INTSUM = OverideComponent(INTSUM_TEMPLATE);

export const INTREP = OverideComponent(INTREP_TEMPLATE);

export enum ReportType {
  DAILY_GLOBAL = "Global Daily",
  WEEKLY_GLOBAL = "Global Weekly",
  INTREP = "INTREP",
  SITREP = "SITREP",
  INTSUM = "INTSUM",
  THREAT_FORECAST = "Threat Forecast",
};

interface TemplateMapping {
  [reportType: string]: {
    [sectionName: string]: string;
  };
};

export const templateMapping: TemplateMapping = {
  [ReportType.DAILY_GLOBAL]: {
    main: DAILY_GLOBAL_MAIN,
    "africa_&_middle_east": DAILY_GLOBAL_AFRICA,
    apac: DAILY_GLOBAL_APAC,
    "latin_america_&_the_caribbean": DAILY_GLOBAL_LATIN_AMERICA,
    north_america: DAILY_GLOBAL_NORTH_AMERICA,
    "north_&_west_europe": DAILY_GLOBAL_NORTH_EUROPE,
    "south_&_east_europe": DAILY_GLOBAL_SOUTH_EUROPE,
  },
  [ReportType.WEEKLY_GLOBAL]: {
    main: WEEKLY_GLOBAL_MAIN,
    "africa_&_middle_east": WEEKLY_GLOBAL_AFRICA,
    apac: WEEKLY_GLOBAL_APAC,
    "latin_america_&_the_caribbean": WEEKLY_GLOBAL_LATIN_AMERICA,
    north_america: WEEKLY_GLOBAL_NORTH_AMERICA,
    "north_&_west_europe": WEEKLY_GLOBAL_NORTH_EUROPE,
    "south_&_east_europe": WEEKLY_GLOBAL_SOUTH_EUROPE,
  },
  [ReportType.INTREP]: { main: INTREP },
  [ReportType.SITREP]: { main: SITREP },
  [ReportType.INTSUM]: { main: INTSUM },
  [ReportType.THREAT_FORECAST]: { main: THREAT_FORECAST },
};
