import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BriefParameters = styled(Stack).attrs({
  flex: "1",
  mb: "XS",
})`
  flex-basis: max-content;

  @media (max-width: ${Breakpoints.L}) {
    flex-direction: column;
  }
`;

export const TagParameter = styled(Stack).attrs({
  ml: "S",
})`
  @media (max-width: ${Breakpoints.L}) {
    margin-left: 0px;
    margin-top: ${Spacing.S}px;
  }
`;
