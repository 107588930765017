import { useStore } from "react-context-hook";
import { transformNames } from "../utilities/reportUtils";
import { useSection } from "./useSection";
import { getInitSectionsList } from "../utilities/sectionUtils";
import { ReportType } from "../models/ReportTypeModel";
import { ReportType as ReportTypeTemplete } from "../config/templates";

export const useReportTypes = () => {
  const [reportsTypes, setReportsTypes] = useStore("reportsTypes", []);
  const [filteredReportsTypes, setFilteredReportsTypes] = useStore("filteredReportsTypes", { names: [], readableNames: [] });
  const [readableReportsTypes, setReadableReportsTypes] = useStore("readableReportsTypes", []);
  const { setSections } = useSection();

  const uploadReportsTypes = (schedules: ReportType[]) => {
    setSections(getInitSectionsList(schedules));
    const reportsTypesFiltered = schedules?.filter((r: ReportType) => r.name !== ReportTypeTemplete.DAILY_GLOBAL && r.name !== ReportTypeTemplete.WEEKLY_GLOBAL);

    setReadableReportsTypes(schedules?.map((e: ReportType) => e.name));
    setReportsTypes(schedules?.map((e: ReportType) => transformNames(e.name)));
    setFilteredReportsTypes({
      names: reportsTypesFiltered?.map((e: ReportType) => e.name),
      readableNames: reportsTypesFiltered?.map((e: ReportType) => transformNames(e.name)),
    });
  };

  return { reportsTypes, uploadReportsTypes, readableReportsTypes, filteredReportsTypes };
};
