import {
  Banner,
  Breakpoints,
  Button,
  ButtonCompact,
  ButtonText,
  ContextMenu,
  Segment,
  SegmentedControl,
  Stack,
  Title,
  useHasMaxWidth,
} from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectItems, selectItemsCollection, selectViewMode } from "../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { selectSendoutsEditedId } from "../../../store/sendouts/SendoutsSelectors";
import { selectToolbar } from "../../../store/toolbar/ToolbarSelectors";
import { toolbarActions } from "../../../store/toolbar/ToolbarSlice";
import { Buttons, Header } from "./ItemsListHeader.styles";
import { DeleteButtonWrapper } from "./styled";

export const ItemsListHeader = ({ openExportModal, fetchItems }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const viewMode = useAppSelector(selectViewMode);
  const items = useAppSelector(selectItems);
  const collectedItems = useAppSelector(selectItemsCollection);
  const editedSendoutId = useAppSelector(selectSendoutsEditedId);
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { isOpen, contentType } = useAppSelector(selectToolbar);

  const handleToggleCollection = () => {
    dispatch(toolbarActions.setToolbar({ isOpen: contentType === "collection" ? !isOpen : "true", contentType: "collection" }));
  };

  const onChangeViewMode = (value) => {
    dispatch(itemsActions.setViewMode(value));
  };

  return (
    <Header>
      <Stack mb={!isMobile ? "XXS" : "0"}>
        <Stack flex={1}>
          <Title>{t("Items.listTitle")}</Title>
        </Stack>
        {!isMobile && (
          <Buttons>
            {!!editedSendoutId && (
              <Banner
                action={{
                  label: t("Sendout.collection.cancelEdit"),
                  onClick: () => navigate(`/editSendout/${editedSendoutId}`),
                }}
                message={t("Sendout.collection.cancelEditInfo")}
              />
            )}
            {!editedSendoutId && (
              <ContextMenu
                align="right"
                options={[
                  {
                    icon: "Export",
                    label: t("Items.exportItems"),
                    onClick: openExportModal,
                    disabled: items.length === 0,
                  },
                ]}
                sheetCancelLabel={t("Common.cancel")}
                sheetTitle={t("Common.cancel")}
              >
                <DeleteButtonWrapper>
                  <ButtonText data-testid="exportItems" icon="Options" />
                </DeleteButtonWrapper>
              </ContextMenu>
            )}
            <Button mode="outlined" onClick={handleToggleCollection} fitContent disabled={!collectedItems.length}>
              {t(editedSendoutId ? "Briefs.collection.button.edit" : "Briefs.collection.button.new", { briefsLength: collectedItems.length })}
            </Button>

            {!editedSendoutId && (
              <Button
                data-testid="createItem"
                mode="contained"
                onClick={() => {
                  navigate("/createItem", { replace: true });
                }}
                icon="Plus"
                fitContent
              >
                {t("Items.createNewItem")}
              </Button>
            )}
          </Buttons>
        )}
      </Stack>
      <Stack alignItems="center" justifyContent="space-between" flex="1" gap="S" pt="S">
        <ButtonCompact mode="outlined" onClick={fetchItems} icon="Reload">
          {t("reports.list.refresh")}
        </ButtonCompact>
        {items.length > 0 && (
          <SegmentedControl fitContent onChange={onChangeViewMode} value={viewMode}>
            <Segment title={t("Items.viewMode.longList")} icon="AppSwitcher" />
            <Segment title={t("Items.viewMode.shortList")} icon="ShortView" />
            <Segment title={t("Items.viewMode.map")} icon="Location" />
          </SegmentedControl>
        )}
      </Stack>
    </Header>
  );
};
