import { Box, ButtonText, CountIndicator, Icon, Separator, Skeleton, Stack, Text } from "@secuis/ccp-react-components";
import { format } from "date-fns";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectItemsCollection } from "../../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../../store/items/ItemsSlice";
import { useDeleteSendoutItemMutation } from "../../../../store/sendouts/SendoutsApi";
import { selectSendoutsEditedId } from "../../../../store/sendouts/SendoutsSelectors";
import { getEndDate } from "../../../../utilities/Item.helpers";
import { getDateWithoutTimezone } from "../../../../utilities/dateUtils";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { BriefContainer } from "./CollectedBriefs.style";

const MAX_BRIEFS = 3;

interface RegionBriefProps {
  region: string;
  isFetching?: boolean;
}

export const RegionBrief = ({ region, isFetching }: RegionBriefProps) => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const { sendoutId: selectedSendoutId } = useParams();
  const collectedItems = useAppSelector(selectItemsCollection);
  const editedSendoutId = useAppSelector(selectSendoutsEditedId);
  const dispatch = useAppDispatch();
  const [deleteSendoutItem] = useDeleteSendoutItemMutation();
  const regionBriefs = collectedItems.filter((item) => item.region === region);

  const handleReleaseBrief = (id: string) => {
    dispatch(itemsActions.releaseItem(id));
    if (editedSendoutId) {
      deleteSendoutItem({ sendoutId: editedSendoutId, itemId: id })
        .unwrap()
        .then(() => {
          toast({
            title: t("Sendout.saved.toast.success"),
            type: NotificationTypes.Confirmation,
            icon: "Success",
          });
        })
        .catch(() => true);
    }
  };

  return (
    regionBriefs.length > 0 && (
      <Stack key={region} direction="column" justifyContent="space-between">
        <Box pv="S">
          <Separator mb="S" />
          <Stack justifyContent="space-between">
            <Text small>{getTranslatedTag(region, "region")}</Text>
            {regionBriefs?.length === MAX_BRIEFS ? (
              <Icon variant="Success" color="success" />
            ) : (
              <CountIndicator count={regionBriefs.length} position="relative" color={regionBriefs.length > 3 ? "high" : "accent"} />
            )}
          </Stack>
        </Box>
        {regionBriefs?.map((brief) => {
          const endDate = getEndDate(brief);

          return isFetching ? (
            <Stack mb="XXS" key={brief.id}>
              <Skeleton mode="text" typography="title" style={{ height: "52px", width: "100%" }} />
            </Stack>
          ) : (
            <BriefContainer key={brief.id}>
              <Stack gap={"XS"} justifyContent="flex-end" direction="column">
                <Stack justifyContent="flex-end">
                  <Text micro>
                    {`${format(getDateWithoutTimezone(brief.start_date_of_event), "iii, d MMM yyyy")}`}
                    {endDate ? ` - ${endDate}` : ""}
                  </Text>
                </Stack>
                <Stack justifyContent="space-between">
                  <Text micro truncate>
                    {brief.title}
                  </Text>
                  {!selectedSendoutId && <ButtonText icon="Delete" color="error" iconSize="S" onClick={() => handleReleaseBrief(brief.id)} />}
                </Stack>
              </Stack>
            </BriefContainer>
          );
        })}
      </Stack>
    )
  );
};
