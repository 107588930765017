export const RiskIntelligenceAdmin = "RiskIntelligenceAdmin";
export const RiskIntelligenceApprover = "RiskIntelligenceApprover";
export const RiskIntelligenceEditor = "RiskIntelligenceEditor";
export const AwarenessReportAdmin = "AwarenessReportAdmin";
export const AwarenessReportViewer = "AwarenessReportViewer";
export const InternalRiskIntelligenceViewer = "InternalRiskIntelligenceViewer";
export const InternalRiskIntelligenceAdmin = "InternalRiskIntelligenceAdmin";

export const CreateReportRoles = [
  RiskIntelligenceAdmin,
  RiskIntelligenceEditor,
  RiskIntelligenceApprover,
];

export const ApproverRoles = [RiskIntelligenceAdmin, RiskIntelligenceApprover];
export const ShowEmailRoles = [AwarenessReportAdmin, AwarenessReportViewer, RiskIntelligenceAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShowContactUsRoles = [AwarenessReportAdmin, AwarenessReportViewer,RiskIntelligenceAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShareReportRoles = [AwarenessReportAdmin, AwarenessReportViewer, RiskIntelligenceAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer,RiskIntelligenceApprover,RiskIntelligenceEditor];
export const BriefsOnlyRoles = [InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer, AwarenessReportAdmin, AwarenessReportViewer];
export const ItemsHiddenRoles = [AwarenessReportViewer, AwarenessReportAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShareBriefRoles = [AwarenessReportAdmin, AwarenessReportViewer, RiskIntelligenceAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer,RiskIntelligenceApprover,RiskIntelligenceEditor];
