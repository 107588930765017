import { Input } from "@secuis/ccp-react-components";
import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { selectIsDraft } from "../../../store/item/ItemSelectors";
import { LATITUDE_REGEX, LONGITUDE_REGEX } from "../Map/Map.helpers";
import { LocationWrapper } from "./ItemDescription.styles";

export const GeolocationInput = () => {
  const { t } = useTranslation();
  const isDraft = useAppSelector(selectIsDraft);
  const { control, getFieldState, setError, clearErrors } = useFormContext();

  const geolocation = useWatch({ control, name: "geolocation" });

  const isGeolocationValid = (geolocation: Geolocation, isDraft: boolean): boolean => {
    const allCoordinates = Object.values(geolocation);
    return allCoordinates.every((coordinate) => coordinate) || (isDraft && allCoordinates.every((coordinate) => !coordinate));
  };

  useEffect(() => {
    if (!geolocation) return;

    if (isGeolocationValid(geolocation, isDraft)) {
      clearErrors("geolocationInput");
    } else {
      setError("geolocationInput", { message: t("Items.incorrectCooridinates") });
    }
  }, [clearErrors, geolocation, isDraft, setError, t]);

  return (
    <LocationWrapper>
      <Controller
        name="geolocation.latitude"
        control={control}
        rules={{
          required: {
            value: !isDraft,
            message: t("Items.incorrectCooridinates"),
          },
          pattern: {
            value: LATITUDE_REGEX,
            message: t("Items.incorrectCooridinates"),
          },
        }}
        defaultValue=""
        render={({ field, fieldState: { invalid } }) => (
          <Input
            name="geolocation.latitude"
            invalid={invalid}
            error={getFieldState("geolocation.latitude")?.error?.message}
            autoComplete="off"
            label={t("Items.lat")}
            placeholder={t("Items.lat")}
            {...field}
          />
        )}
      />
      <Controller
        name="geolocation.longitude"
        control={control}
        rules={{
          required: {
            value: !isDraft,
            message: t("Items.incorrectCooridinates"),
          },
          pattern: {
            value: LONGITUDE_REGEX,
            message: t("Items.incorrectCooridinates"),
          },
        }}
        defaultValue=""
        render={({ field, fieldState: { invalid } }) => (
          <Input
            invalid={invalid}
            error={getFieldState("geolocation.longitude")?.error?.message}
            autoComplete="off"
            label={t("Items.long")}
            placeholder={t("Items.long")}
            {...field}
          />
        )}
      />
    </LocationWrapper>
  );
};
