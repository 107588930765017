import { Stack, Text } from "@secuis/ccp-react-components";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AUTOSAVE_INTERVAL } from "../../../constants/generalSettings";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { useInterval } from "../../../hooks/useInterval";
import { ITEM_STATUS, Item } from "../../../models/ItemModel";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectIsSaveLocked } from "../../../store/item/ItemSelectors";
import { itemActions } from "../../../store/item/ItemSlice";
import { useGetItemQuery, usePatchItemMutation, usePostItemMutation } from "../../../store/items/ItemsApi";
import ItemPreviewModal from "../Preview/ItemPreviewModal";
import { Content } from "./CreateItem.styles";
import CreateItemHeader from "./CreateItemHeader";
import ImagePreview from "./ImagePreview";
import ImageUpload from "./ImageUpload";
import ItemDescription from "./ItemDescription";
import { StickyHeader } from "./StickyHeader";
import ThreatsAndRisks from "./ThreatsAndRisks";
import { ItemsWrapper } from "./styled";

type Inputs = Item & {
  externalOrInternal: string;
};

export const CreateItem = ({ scrollRef = null }) => {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSaveLocked = useAppSelector(selectIsSaveLocked);
  const { toast } = useContext(NotificationsContext);
  const showEditWarning = useRef(false);

  const formMethods = useForm<Inputs>({ mode: "onChange" });
  const {
    getValues,
    formState: { isDirty: isFormDirty, dirtyFields, errors, isValid: isFormValid },
    reset,
    setValue,
  } = formMethods;

  const [patchItem] = usePatchItemMutation();
  const [postItem, { data: createdItem }] = usePostItemMutation();
  const { data: fetchedItem } = useGetItemQuery(itemId, { skip: !itemId, refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(itemActions.setIsDraft(!itemId || fetchedItem?.item_status === ITEM_STATUS.DRAFT));
    dispatch(itemActions.setIsSaveLocked(!itemId));
  }, [dispatch, fetchedItem?.item_status, itemId]);

  const [stickyScroll, setStickyScroll] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop } = scrollRef.current;
      if (scrollTop >= 40 && !stickyScroll) {
        setStickyScroll(true);
      } else if (!scrollTop) {
        setStickyScroll(false);
      }
    }
  }, [scrollRef, stickyScroll]);

  useEffect(() => {
    const ref = scrollRef?.current;
    if (ref) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => ref.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current]);

  useEffect(() => {
    if (fetchedItem) {
      if (fetchedItem?.is_in_sendouts && !showEditWarning.current) {
        toast({
          title: t("Item.edit.isInSendouts.title"),
          message: t("Item.edit.isInSendouts.message"),
          type: NotificationTypes.Warning,
          icon: "Warning",
        });
        showEditWarning.current = true;
      }
      reset({
        externalOrInternal: fetchedItem.is_internal ? "internal" : "external",
        ...fetchedItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedItem, showEditWarning?.current]);

  useEffect(() => {
    if (createdItem) {
      navigate(`/editItem/${createdItem.id}`, { replace: true });
    }
  }, [createdItem, navigate]);

  const getUpdatedFields = (): Partial<Inputs> => {
    let changedFields = {};

    Object.entries(dirtyFields)
      .filter(([, value]) => value === true)
      .map(([key]) => (changedFields[key] = getValues(key as keyof Inputs)));
    if (dirtyFields.geolocation?.latitude || dirtyFields.geolocation?.longitude) {
      changedFields = {
        ...changedFields,
        geolocation: {
          latitude: parseFloat(getValues("geolocation.latitude").toString()),
          longitude: parseFloat(getValues("geolocation.longitude").toString()),
        },
      };
    }
    return changedFields;
  };

  const preparePayload = (data: Partial<Inputs>): Partial<Inputs> => {
    const payload = { ...data };

    if (dirtyFields.set_reminder && data.set_reminder === null) {
      payload.set_reminder = "0001-01-01T00:00:00Z";
    }

    if (dirtyFields.externalOrInternal) {
      payload.is_internal = data.externalOrInternal === "internal";
    }

    payload.type = (!itemId && "standard") || undefined;
    return payload;
  };

  const updateItemState = (response) => {
    if (response?.item_status && response.item_status === ITEM_STATUS.ONGOING) {
      setValue("item_status", response.item_status);
      dispatch(itemActions.setIsDraft(false));
    }
    setValue("event_status", response.event_status);
    setValue("reviewed_by", response.reviewed_by);
    setValue("date_reviewed", response.date_reviewed);
    setValue("ric_region_daily", response.ric_region_daily);
    setValue("last_updated_by", response.last_updated_by);
    setValue("date_last_updated", response.date_last_updated);
    reset(getValues(), { keepValues: true });
  };

  useInterval(() => {
    const hasErrors = Object.entries(errors).length > 0;
    if (!isFormDirty || !isFormValid || hasErrors || (!itemId && isSaveLocked)) {
      return;
    }
    const updatedFields = getUpdatedFields();
    const payload = preparePayload(updatedFields);

    if (!itemId) {
      postItem(payload)
        .unwrap()
        .then(updateItemState)
        .catch(() => true);
    } else {
      patchItem({ id: itemId, item: payload })
        .unwrap()
        .then(updateItemState)
        .catch(() => true);
    }
  }, AUTOSAVE_INTERVAL);

  return (
    <ItemsWrapper data-testid={"createItemWrapper"}>
      <FormProvider {...formMethods}>
        <StickyHeader stickyScroll={stickyScroll} itemId={itemId} />
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <CreateItemHeader fetchedItem={fetchedItem} />
          <Content>
            <Stack direction="column" gap="M">
              <Text bold>{t("Items.description")}</Text>
              {fetchedItem && (fetchedItem.image_url ? <ImagePreview item={fetchedItem} /> : <ImageUpload item={fetchedItem} />)}
              <ItemDescription />
            </Stack>
            <Stack direction="column" gap="M">
              <ThreatsAndRisks />
            </Stack>
          </Content>
        </form>
      </FormProvider>
      <ItemPreviewModal />
    </ItemsWrapper>
  );
};
