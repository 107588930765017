import { EmptyState, LoadingIndicator } from "@secuis/ccp-react-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useLazyGetItemsQuery } from "../../../store/items/ItemsApi";
import { selectItems, selectItemsFilters, selectItemsPage, selectItemsShowMore, selectItemsStatus, selectViewMode } from "../../../store/items/ItemsSelectors";
import { toolbarActions } from "../../../store/toolbar/ToolbarSlice";
import { ViewMode } from "../../../utilities/types";
import { ListContainer } from "../../Reports/List/ReportsList.styles";
import ItemsMap from "../Map/ItemsMap";
import ItemPreviewModal from "../Preview/ItemPreviewModal";
import { ExportItemsModal } from "./ExportItemsModal";
import { ItemLong } from "./ItemLong";
import { ItemShort } from "./ItemShort";
import { ItemsListHeader } from "./ItemsListHeader";
import { CenteredWrapper, ItemsWrapper, LoadingWrapper } from "./styled";

export const ItemsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const itemsList = useAppSelector(selectItems);
  const viewMode = useAppSelector(selectViewMode);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const itemsStatus = useAppSelector(selectItemsStatus);
  const showMore = useAppSelector(selectItemsShowMore);
  const page = useAppSelector(selectItemsPage);
  const filters = useAppSelector(selectItemsFilters);
  const [getItems] = useLazyGetItemsQuery();

  const fetchItems = useCallback(
    (page: number) => {
      getItems({ page, filters });
    },
    [getItems, filters]
  );

  useEffect(() => {
    dispatch(
      toolbarActions.setToolbar({
        visible: true,
        contentType: "filters",
        storeType: "items",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchItems(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsWrapper>
      <ItemsListHeader openExportModal={() => setIsExportModalOpen(true)} fetchItems={() => fetchItems(1)} />
      {itemsStatus === "fulfilled" && itemsList.length > 0 && (
        <>
          {viewMode === ViewMode.map ? (
            <ItemsMap items={itemsList} />
          ) : (
            <ListContainer id="scrollableItemsDiv" data-testid="scrollableItemsDiv" mt="XS">
              <InfiniteScroll
                dataLength={itemsList.length}
                next={() => fetchItems(page + 1)}
                hasMore={showMore}
                loader={
                  <LoadingWrapper>
                    <LoadingIndicator size="L" />
                  </LoadingWrapper>
                }
                scrollableTarget="scrollableItemsDiv"
              >
                {itemsList.map((item, index) =>
                  viewMode === ViewMode.long ? (
                    <ItemLong key={item?.id} last={itemsList.length - 1 === index} itemData={item} />
                  ) : (
                    <ItemShort key={item?.id} last={itemsList.length - 1 === index} itemData={item} />
                  )
                )}
              </InfiniteScroll>
            </ListContainer>
          )}
        </>
      )}
      {itemsStatus === "pending" && (
        <CenteredWrapper>
          <LoadingIndicator size="L" />
        </CenteredWrapper>
      )}
      {itemsStatus === "fulfilled" && !itemsList.length && (
        <CenteredWrapper>
          <EmptyState icon="Risk" title={t("Items.list.emptyState")} />
        </CenteredWrapper>
      )}
      {itemsStatus === "rejected" && !itemsList.length && (
        <CenteredWrapper>
          <EmptyState icon="Warning" title={t("Items.list.errorState")} />
        </CenteredWrapper>
      )}
      <ExportItemsModal isOpen={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} filters={filters} />
      <ItemPreviewModal />
    </ItemsWrapper>
  );
};
