import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDataModel, UserModel } from "../../models/UserModel";
import { Preference, userApi } from "./UserApi";
import { FetchStatus } from "../../models/ReportModel";

type PreferencesEntry = {
  [key: string]: string | boolean;
};

type UserState = {
  data: UserDataModel;
  userId?: string;
  userToken?: string;
  preferences?: PreferencesEntry;
  byId: Record<string, UserModel>;
  status: FetchStatus;
};

export const initialState: UserState = {
  data: {
    name: "",
    isEmbedded: true,
    isEmbeddedInMobile: false,
    isUserNameFilled: false,
    roles: [],
    email: "",
    isLoaded: false,
  },
  byId: {},
  status: "idle",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<Partial<UserDataModel>>) => {
      state.data = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
    updatePreference: (state, action: PayloadAction<PreferencesEntry>) => {
      if (action?.payload && Object.keys(action.payload).length > 0) {
        if (state?.preferences?.hasOwnProperty(Object.keys(action.payload)[0])) {
          state.preferences[Object.keys(action.payload)[0]] = Object.values(action.payload)[0];
        } else {
          state.preferences = { ...state.preferences, [Object.keys(action.payload)[0]]: Object.values(action.payload)[0] };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.getPreferences.matchPending, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(userApi.endpoints.getPreferences.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(userApi.endpoints.getPreferences.matchFulfilled, (state, action) => {
        const tempPreferences = {};
        action.payload?.preferences?.forEach((preference: Preference) => {
          if (preference.type === "bool") {
            tempPreferences[preference.preference_key] = preference.value === "true";
          }
          if (preference.type === "string") {
            tempPreferences[preference.preference_key] = preference.value as unknown as string;
          }
        });
        state.preferences = tempPreferences;
        state.status = action.meta.requestStatus;
      })
      .addMatcher(userApi.endpoints.getUserById.matchFulfilled, (state, action) => {
        state.byId[action.meta.arg.originalArgs] = action.payload;
      });
  },
});

export const userActions = userSlice.actions;
