import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SendoutParameters = styled(Stack).attrs({
  flex: "1",
  mb: "S",
})`
  flex-basis: max-content;

  @media (max-width: ${Breakpoints.M}) {
    flex-direction: column;
  }
`;

export const TagParameter = styled(Stack).attrs({
  ml: "S",
})`
  @media (max-width: ${Breakpoints.M}) {
    margin-left: 0px;
    margin-top: ${Spacing.S}px;
  }
`;

export const Buttons = styled(Stack).attrs({
  alignItems: "center",
  gap: "L",
})`
  @media (max-width: ${Breakpoints.M}) {
    margin-top: ${Spacing.S}px;
    gap: ${Spacing.XS}px;
    width: 100%;
    justify-content: flex-end;
    flex: 1;
  }
`;

interface ListContainerProps {
  $draftHeight?: number;
  $onlyOne?: boolean;
}

export const ListContainer = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
  mt: "M",
})<ListContainerProps>`
  height: ${({ $draftHeight }) => `calc(100vh - ${$draftHeight}px - 150px)`};
  width: 100%;
  overflow-y: ${({ $onlyOne }) => ($onlyOne ? `visible` : `auto`)};
  scrollbar-width: auto;

  @media (max-width: ${Breakpoints.XS}) {
    padding-bottom: 200px;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XS}px;
    overflow: ${({ $onlyOne }) => ($onlyOne ? `visible !important` : `hidden !important`)};
  }
`;
